export const products = [
  {
    id: 1,
    img: "https://i.imgur.com/XJAvx03.jpg",
    link: "https://ta-rental-service-rostysaurus.herokuapp.com/",
    name: "Teachers Assistant Project",
  },
  {
    id: 2,
    img: "https://i.imgur.com/KvvJTrW.png",
    link: "https://super-sponty.herokuapp.com/",
    name: "Sponty Project",
  },
  {
    id: 3,
    img: "https://i.imgur.com/lzHmMVt.png",
    link: "",
    name: "Ryggskolan Sweden (WIP)",
  },
]
